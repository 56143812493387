import React, { useEffect, useRef, useState } from 'react';
import { 
    Container, Typography, Grid, TextField, Button, Box 
} from '@mui/material';
import emailjs from 'emailjs-com'; // Import EmailJS
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';


const ContactUsPage = () => {
    const [mapLocation, setMapLocation] = useState('canada');
    const [message, setMessage] = useState(null); // State to store success/error messages
    const formRef = useRef(null); // Reference to the form
    const hash = window.location.hash;
    // Define the map URLs for both locations
    const mapUrls = {
        canada: "https://www.google.com/maps/embed/v1/place?key=AIzaSyAGeJaWCsvfR1YdK9TzzAjPiRc_zoRfu-o&q=18-115+Woodstream+Blvd,+Woodbridge,+ON",
        pakistan: "https://www.google.com/maps/embed/v1/place?key=AIzaSyAGeJaWCsvfR1YdK9TzzAjPiRc_zoRfu-o&q=12-LG+Grand+Millennium+Hotel+%26+Towers,+9-A+Davis+Road,+Lahore,+Pakistan"
    };

    useEffect(() => {
        if (hash === '#form') {
            const formElement = document.getElementById('form');
            formElement.scrollIntoView({ behavior: 'smooth' });
           
        }
        else {
            window.scrollTo(0, 0);
        }
    }, [hash]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        emailjs.send('service_z7v2kgp', 'template_zhergm4', data, 'oiFp-rJWke5iUCQaE')
            .then((response) => {
                setMessage('Your message has been sent successfully!');
                formRef.current.reset(); // Reset the form fields

                // Clear the message after 3 seconds
                setTimeout(() => {
                    setMessage(null);
                }, 3000);
            })
            .catch((error) => {
                setMessage('There was an error sending your message. Please try again later.');

                // Clear the message after 3 seconds
                setTimeout(() => {
                    setMessage(null);
                }, 3000);
            });
    };

    return (
        <>
       <Helmet>
    <title>Contact Perfect Group Consultants</title>
    <meta name="description" content="Reach out to Perfect Group Consultants for any inquiries. We're here to assist students in Canada and Pakistan with student visa guidance and immigration support." />
    <meta property="og:title" content="Contact Perfect Group Consultants" />
    <meta property="og:description" content="Get in touch with us for immigration and study abroad guidance." />
    <meta property="og:type" content="website" />
    <link rel="canonical" href="https://perfectgroup.ca/contact" />
</Helmet>


<script type="application/ld+json">
{`
    {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Perfect Group Consultants",
        "url": "https://perfectgroup.ca",
        "logo": "https://perfectgroup.ca/logo192.png",
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "+1-416-877-2773",
                "contactType": "customer service"
            },
            {
                "@type": "ContactPoint",
                "telephone": "+92-333-2435888",
                "contactType": "customer service"
            }
        ]
    }
`}
</script>


<Box 
    sx={{ 
        height: '400px', 
        backgroundImage: 'url(/banner-image4.png)', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',  // Center vertically
        justifyContent: 'left',  // Center horizontally
        color: 'black',
        textAlign: 'left',
        '@media (max-width:600px)': {
            backgroundImage: 'none', 
            justifyContent: 'center',
            alignItems: 'center', 
            paddingLeft: "0px"
            
        },
    }}
>
    <Box 
        sx={{ 
            display: 'flex',  // Add this line
            flexDirection: 'column',  // Ensures items stack vertically
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            justifyContent: 'center',
            alignItems: 'left',
            padding: '20px', 
            borderRadius: '8px',
            maxWidth: '720px',
            height: "100%"
        }}
    >
        <Typography variant="h2" fontWeight="bold">
            Contact Perfect Group Consultants
        </Typography>
        <Typography variant="h5">
            Offices in Canada and Pakistan
        </Typography>
    </Box>
</Box>


            <Container id="form" sx={{ my: 8, textAlign: 'center' }}>
                <Typography variant="h5" paragraph>
                    Get In Touch With Us
                </Typography>
                <Typography variant="body1">
    Reach out to Perfect Group Consultants for expert immigration support and student consulting services in Canada and Pakistan.
</Typography>


                {/* Success/Error message */}
                {message && (
                    <Typography variant="subtitle1" color="green" paragraph>
                        {message}
                    </Typography>
                )}

                <form onSubmit={handleSubmit} ref={formRef}>
                    <Grid container spacing={2} justifyContent="center" sx={{ maxWidth: '600px', margin: '0 auto' }}>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="First Name" variant="outlined" required name="first_name" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Last Name" variant="outlined" required name="last_name" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Email Address" variant="outlined" required type="email" name="email" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Mobile Number" variant="outlined" required type="tel" name="mobile" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Study Destination" variant="outlined" name="country" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField fullWidth label="Preferred Course" variant="outlined" name="course" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth 
                                multiline 
                                rows={3} 
                                label="Message or Query" 
                                variant="outlined" 
                                name="counseling" 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth type="submit">
                                Send Your Message
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>

            <Container sx={{ mb: 8, textAlign: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
                    <Button 
                        variant={mapLocation === 'canada' ? 'contained' : 'outlined'} 
                        onClick={() => setMapLocation('canada')}
                    >
                        Canada Office
                    </Button>
                    <Button 
                        variant={mapLocation === 'pakistan' ? 'contained' : 'outlined'} 
                        onClick={() => setMapLocation('pakistan')}
                    >
                        Pakistan Office
                    </Button>
                </Box>

                <iframe
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={mapUrls[mapLocation]}
                ></iframe>


<Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
    <Button component={RouterLink} to="/study-abroad" variant="outlined">
        Study Abroad Services
    </Button>
    <Button component={RouterLink} to="/student-essentials" variant="outlined">
        Student Essentials
    </Button>
    <Button component={RouterLink} to="/why-us" variant="outlined">
        Why Choose Us
    </Button>
</Box>
            </Container>

            
        </>
    );
};

export default ContactUsPage;