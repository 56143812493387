import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Slider from "react-slick";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// Import react-slick CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TestimonialsSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const testimonials = [
    {
        name: "Umer Asim",
        path: "/uk.webp",
        review:
          "Exceptional support from start to finish! Thanks to Perfect Group, I successfully secured admission to the University of Northampton. Their team made the entire process easy and provided guidance at every step. Highly recommend their services to anyone aiming to study in the UK!",
        study: "Business Management",
        institution: "University of Northampton, UK",
        date: "July 19, 2023",
      },
      {
        name: "Raid Choudhary and Fawaz Choudhary",
        path: "/cyprus.webp",
        review:
          "Thanks to the exceptional guidance from Perfect Group, we embarked on our journey to study mechanical engineering at Eastern Mediterranean University in Cyprus. The support we received made all the difference, setting us up for successful careers. One of us is now thriving in Germany, while the other is excelling in an exciting field with a good career as well. We’re truly grateful for the start Perfect Group gave us!",
        study: "Mechanical Engineering",
        institution: "Eastern Mediterranean University (EMU), Cyprus",
        date: "September 15, 2023",
      },
      {
        name: "Ibrahim Mansoor Butt",
        path: "/canada1.webp",
        review:
          "Perfect Group made my admission journey a breeze! Their team guided me through every step, from application to settling in Canada. Thanks to their expert support, I’m now studying Data Analytics at the University of Prince Edward Island, ready to pursue my career goals. Highly recommend their services!",
        study: "Data Analytics",
        institution: "University of Prince Edward Island, Canada",
        date: "August 25, 2023",
      },
      
    {
      name: "Aneela Gill",
      path: "/ireland.webp",
      review:
        "Thanks to Perfect Group, I have studied Tourism and Travel in Ireland! Their expert guidance made everything so easy, from admissions to selecting the institute. Highly recommend them for making dreams come true!",
      study: "Tourism and Travel",
      institution: "Monaghan Institute Ireland, Ireland",
      date: "May 8, 2023",
    },
    {
      name: "Berrender Singh",
      path: "/canada2.webp",
      review:
        "Perfect Group helped me make the best choice! Their team guided me through my options and with their support, I chose Durham College for Supply Chain Management. The whole process was smooth and stress-free thanks to their expertise. I couldn’t be happier with my decision!",
      study: "Supply Chain Management",
      institution: "Durham College, Canada",
      date: "June 12, 2023",
    },
  
  ];

  const sliderRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const handleOpen = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTestimonial(null);
  };

  const PrevArrow = ({ onClick }) => (
    <ArrowBackIosIcon
      onClick={onClick}
      aria-label="Previous testimonial"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") onClick();
      }}
      sx={{
        cursor: "pointer",
        fontSize: "1.5rem",
        color: "#666",
        "&:hover": { color: "red" },
      }}
    />
  );

  const NextArrow = ({ onClick }) => (
    <ArrowForwardIosIcon
      onClick={onClick}
      aria-label="Next testimonial"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") onClick();
      }}
      sx={{
        cursor: "pointer",
        fontSize: "1.5rem",
        color: "#666",
        "&:hover": { color: "red" },
      }}
    />
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "20%",
    arrows: false,
    customPaging: (i) => <span className="slick-dot"></span>,
    appendDots: (dots) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            margin: "0 10px",
          }}
        >
          {dots}
        </Box>
        <NextArrow onClick={() => sliderRef.current.slickNext()} />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f9f9f9",
        padding: "50px 0",
        position: "relative",
      }}
    >
      <style>
        {`
          .slick-dots {
            display: flex !important;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            list-style: none;
          }

          .slick-dots li {
            margin: 0 5px;
          }

          .slick-dot {
            width: 12px;
            height: 12px;
            background-color: #ddd;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color 0.3s ease;
            display: inline-block;
          }

          .slick-dots li.slick-active .slick-dot {
            background-color: red;
          }

          /* Hide default arrows */
          .slick-prev, .slick-next {
            display: none !important;
          }
        `}
      </style>

      <Box textAlign="center" marginBottom={"2vh"} paddingBottom={"2vh"}>
        <Typography variant="h4" gutterBottom>
          What Our Clients Say About Us
        </Typography>
        <Box
          sx={{
            width: "80px",
            height: "3px",
            backgroundColor: "red",
            margin: "0 auto",
          }}
        />
      </Box>
      <Slider {...settings} ref={sliderRef}>
        {testimonials.map((testimonial, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                maxWidth: "700px",
                width: "90%",
                height: isSmallScreen ? "auto" : "300px",
                padding: "20px",
                borderRadius: "10px",
                margin: "0 auto",
                overflow: "hidden",
              }}
            >
              <Box
                component="img"
                src={testimonial.path}
                alt={`${testimonial.name}'s photo`}
                sx={{
                  width: { xs: "100%", md: "40%" },
                  height: isSmallScreen ? "500px" : "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                  marginBottom: { xs: "10px", md: "0" },
                  marginRight: { xs: "0", md: "20px" },
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Typography variant="body2" color="textSecondary">
                  {testimonial.date}
                </Typography>
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {testimonial.institution}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {testimonial.study}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginTop: 1,
                    ...(isSmallScreen && {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                    }),
                  }}
                >
                  {testimonial.review}
                </Typography>
                {isSmallScreen && (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ marginTop: 2, alignSelf: "flex-start" }}
                    onClick={() => handleOpen(testimonial)}
                  >
                    Read More
                  </Button>
                )}
              </Box>
            </Paper>
          </Box>
        ))}
      </Slider>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="testimonial-modal-title"
        aria-describedby="testimonial-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            maxWidth: "500px",
            padding: "20px",
            textAlign: "center",
            borderRadius: "10px",
            outline: "none",
          }}
        >
          {selectedTestimonial && (
            <>
              <Typography id="testimonial-modal-title" variant="h6" gutterBottom>
                {selectedTestimonial.name}
              </Typography>
              <Typography
                id="testimonial-modal-description"
                variant="body1"
                sx={{ marginTop: 2 }}
              >
                {selectedTestimonial.review}
              </Typography>
            </>
          )}
        </Paper>
      </Modal>
    </Box>
  );
}

export default TestimonialsSection;
